<!-- 基础设置 -->
<template>
	<div class="bg-white px-3" style="margin: -20px;margin-top: -1rem;margin-bottom: 0!important;">
		<el-tabs v-model="activeName">
			

		    <el-tab-pane label="积分获取设置" name="first">
		    	<el-form ref="form" :model="form" label-width="160px">
		    		<el-form-item label="创作积分">
		    			<el-input v-model="form.czjf" type="number" size="mini" style="width: 25%;"></el-input>
		    		</el-form-item>
					<el-form-item label="保存原图">
						<el-input v-model="form.bcjf" type="number" size="mini" style="width: 25%;"></el-input>
					</el-form-item>
					<!-- <el-form-item label="分享好友">
						<el-input v-model="form.fxhy" type="number" size="mini" style="width: 25%;"></el-input>
					</el-form-item> -->
					<el-form-item label="分享到群">
						<el-input v-model="form.fxdq" type="number" size="mini" style="width: 25%;"></el-input>
					</el-form-item>
					<el-form-item label="观看广告">
						<el-input v-model="form.gkgg" type="number" size="mini" style="width: 25%;"></el-input>
					</el-form-item>
					<el-form-item label="签到积分">
						<el-input v-model="form.qdjf" type="number" size="mini" style="width: 25%;"></el-input>
					</el-form-item>
		    	</el-form>
		    </el-tab-pane>

			<el-tab-pane label="积分购买设置" name="second">
				<el-form ref="form" :model="form" label-width="160px">
					<el-form-item label="充值方案A费用">
						<el-input v-model="form.cza" type="number" size="mini" style="width: 25%;"></el-input>
					</el-form-item>
					<el-form-item label="充值方案A积分">
						<el-input v-model="form.czaa" type="number" size="mini" style="width: 25%;"></el-input>
					</el-form-item>
					<el-form-item label="充值方案B费用">
						<el-input v-model="form.czb" type="number" size="mini" style="width: 25%;"></el-input>
					</el-form-item>
					<el-form-item label="充值方案B积分">
						<el-input v-model="form.czbb" type="number" size="mini" style="width: 25%;"></el-input>
					</el-form-item>
					<el-form-item label="充值方案C费用">
						<el-input v-model="form.czc" type="number" size="mini" style="width: 25%;"></el-input>
					</el-form-item>
					<el-form-item label="充值方案C积分">
						<el-input v-model="form.czcc" type="number" size="mini" style="width: 25%;"></el-input>
					</el-form-item>
					<el-form-item label="充值方案D费用">
						<el-input v-model="form.czd" type="number" size="mini" style="width: 25%;"></el-input>
					</el-form-item>
					<el-form-item label="充值方案D积分">
						<el-input v-model="form.czdd" type="number" size="mini" style="width: 25%;"></el-input>
					</el-form-item>
				</el-form>
			</el-tab-pane>
		</el-tabs>
	
		<el-form label-width="160px">
			<el-form-item>
				<el-button type="primary" size="mini" @click="submit">保存</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	export default {
		inject:['layout'],
		data() {
			return {
				activeName:"first",
				form: {}
			}
		},
		// 页面初始化
		created(){
			this.getData()
		},
		methods: {
			getData() {
				this.layout.showLoading()
				this.axios.get('/admin/jcsz',{ //基础设置api
					token:true
				}).then(res=>{
					this.form = res.data.data
					this.layout.hideLoading()
				}).catch(err=>{
					this.layout.hideLoading()
				})
			},
			
			//提交事件
			submit(){
				this.layout.showLoading()
				this.axios.post('/admin/jcsz',this.form,{ //提交设置数据api
					token:true
				}).then(res=>{
					this.$message({
						type:"success",
						message:"修改成功"
					})
					this.getData()
					this.layout.hideLoading()
				}).catch(err=>{
					this.layout.hideLoading()
				})
			}
		},
	}
</script>

<style>
</style>
